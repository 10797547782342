.appbar {
  box-shadow: 0px 0px 0px -3px rgb(0 0 0 / 20%),
    0px -2px 10px 0px rgb(0 0 0 / 14%), 0px -3px 0px 0px rgb(0 0 0 / 12%) !important;
}
.css-r6ewbb-MuiToolbar-root {
  justify-content: space-between;
}
.applicable-policy span {
  font-size: 14px;
  color: #00000066;
}
