.modal-btn {
  padding: 15px 80px;
  background: linear-gradient(92.12deg, #004e92 0.11%, #000428 99.81%);
  color: #fff;
  text-align: center;
  display: grid;
  align-content: center;
  margin: 28px auto;
  cursor: pointer;
  width: 239px;
  height: 38px;
}
.policy-modal {
  width: 514px !important;
  height: 329px;
  align-items: center;
  padding: 30px 61px !important;
  border: unset !important;
  border-radius: 10px;
}
.policy-modal h2 {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
.policy-modal p {
  text-align: center;
  font-size: 14px !important;
  margin-top: 8px;
}
.modal-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 5px;
}
