.form-box {
  border: 1px solid #72767e;
  padding: 20px;
}
input,
.select-policy {
  display: block;
  box-sizing: border-box;
  border-radius: 10px !important;
  border: 1px solid #004e924d !important;
  padding: 10px 15px !important;
  margin-bottom: 10px;
  font-size: 14px;
  width: 100%;
  height: 53px;
  color: #000000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}
.addPolicy-container {
  padding-bottom: 15px;
}

.devider {
  border: 1px solid #72767e;
  margin-left: -20px !important;
  width: calc(100% + 40px) !important;
}
/* .policy-form {
  padding: 20px;
} */
label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 8px;
  /* margin-top: 20px; */
  font-size: 14px !important;
  color: #000000;
  font-weight: 500;
}
.select-policy .MuiSelect-select {
  padding: unset;
  color: #000000;
}
.select-policy fieldset {
  border: none;
}
.select-policy span {
  border: none;
  font-size: 14px;
  opacity: 0.6;
}
.select-policy-container {
  margin: unset !important;
  margin-top: unset;
  width: 100% !important;
}
textarea {
  width: 100% !important;
  height: 83px !important;
  resize: unset;
  background: #fcfcfc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border: 1px solid #004e924d;
  border-radius: 10px !important;
  font-size: 14px;
  color: #000000;
  font-family: unset !important;
  padding: 15px;
}
.date-start {
  min-width: 100% !important;
  height: 53px;
}
.box-btn {
  display: flex;
  align-items: center;
}
.box-btn {
  display: flex;
  align-items: center;
}
.addicon,
.clearIcon {
  background: #013f7c;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  margin-left: 15px;
  margin-bottom: 10px;
}
.addicon svg,
.clearIcon svg {
  fill: #fff;
}
.Geofence {
  border: 1px solid #72767e;
  padding: 20px;
}
.Geofence-inrr {
  display: flex;
  align-items: center;
  border: 1px dashed;
  width: 302px;
  padding: 15px;
  border-radius: 10px;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}
.Geofence-inrr a {
  color: #013067 !important;
}
.Geofence-view a {
  color: #013067 !important;
  font-size: 14px;
  font-weight: 500;
}
.Geofence-inrr svg {
  font-size: 20px;
}
.Rules {
  border: 1px solid #72767e;
  padding: 20px;
}
.Rules > textarea {
  height: 313px !important;
}
.footer-btn {
  display: flex;
}
.footer-btn .back {
  margin-right: 30px;
}
.footer-btn .back,
.footer-btn .save {
  border: 1px solid #004e92;
  text-align: center;
  height: 40px;
  width: 230px;
}
.applicable-tab .applicable-policy {
  min-width: 218px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border: 1px solid #004e924d;
  height: 53px;
}
.applicable-tab .applicable-policy fieldset {
  border: none;
}
.footer-btn .save {
  background: linear-gradient(92.12deg, #004e92 0.11%, #000428 99.81%);
  color: #fff;
}

.css-1xhypcz-MuiStack-root {
  padding-top: unset !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 31px !important;
  border-radius: 10px !important;
}
.MuiInputAdornment-root {
  position: absolute;
  right: 20px;
}
fieldset {
  border: unset !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: unset !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}
.width30 {
  width: 30%;
}
.width25 {
  width: 22.5%;
}
@media screen and (min-width: 678px) and (max-width: 1024px) {
  .row-form,
  .country-row,
  .policy-doc {
    display: block !important;
  }
  input,
  .select-policy {
    min-width: 100%;
  }
  .select-policy {
    max-width: 100% !important;
    min-width: 100%;
  }
  .select-policy-container {
    width: 100%;
  }
  .policy-doc input {
    min-width: 100%;
  }
  .policy-doc .applicable-policy {
    min-width: 100%;
  }
  .policy-doc {
    width: 100% !important;
  }
  .footer-btn .back {
    text-align: center;
  }
  .width30 {
    width: 100%;
  }
  .width25 {
    width: 100%;
  }
}
