.policy-document-link {
  max-width: 20px;
}
.policy-details-container {
  position: relative;
  width: 100%;
}
.form-data {
  border: 1px solid #72767e;
}
.policy-details-header {
  border-bottom: 1px solid #004e924d;
  width: 100%;
  padding: 20px;
}
.geofence-view {
  border: 1px solid #72767e;
}
.footer-btn {
  display: flex;
}
.footer-btn .back {
  margin-right: 30px;
}
.footer-btn .back,
.footer-btn .save {
  border: 1px solid #004e92;
  height: 40px;
  width: 230px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.footer-btn .save {
  background: linear-gradient(92.12deg, #004e92 0.11%, #000428 99.81%);
  color: #fff;
}
.css-1r215c6-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus {
  background-color: #ffff !important;
}
.status[data-value="Active"] {
  color: #32983c;
}
.status[data-value="Publish"] {
  color: #013873;
}
.status[data-value="Inactive"] {
  color: #ef7979;
}
.css-1r215c6-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1r215c6-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1r215c6-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: unset;
}
.select-policy-details .select-policy {
  min-width: 181px;
}
.MuiSelect-select[aria-label="Active"] {
  color: #32983c;
}
.MuiSelect-select[aria-label="Publish"] {
  color: #013873;
}
.MuiSelect-select[aria-label="Inactive"] {
  color: #ef7979;
}

.status[aria-selected="true"] {
  display: none;
}
.button {
  cursor: pointer;
}
@media screen and (min-width: 678px) and (max-width: 1024px) {
  .select-policy-details .select-policy {
    min-width: 100% !important;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1225px) {
  .select-policy {
    width: 100% !important;
  }
  .select-policy-details .select-policy {
    min-width: 100% !important;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1340px) {
  .select-policy-details .select-policy {
    min-width: 100% !important;
  }
}
