* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Poppins"; */
}
body {
  margin: 0;
}

/* @media screen and (min-width: 1025px) and (max-width: 1440px) {
  .policy-wrapper {
    max-width: 1100px;
  }
} */
.policy-wrapper {
  padding: 25px 25px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
