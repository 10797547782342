.cards {
  display: grid;
  background: #fcfcfc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin: 15px;
  width: 30%;
  min-height: 137px;
  border: 1px solid #004e9280;
  border-radius: 10px !important;
  pointer-events: none;
}
.cards p {
  color: #3e4059;
}
.cards .MuiTypography-h5 {
  background: linear-gradient(180deg, #000428 0%, #004e92 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}
.cards-content {
  display: grid;
}
@media screen and (min-width: 678px) and (max-width: 1024px) {
  .cards {
    box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
    border-radius: 10px;
    margin: 10px;
    width: 26%;
    min-height: 100px;
  }
  .tabs .MuiTabs-flexContainer {
    width: 200px !important;
    font-size: 10px;
  }
  .tabs .MuiTabs-flexContainer button {
    font-size: 12px !important;
  }
  .createPolicy {
    font-size: 12px !important;
    margin-left: -10px !important;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1212px) {
  .cards {
    box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
    border-radius: 10px;
    margin: 10px;
    width: 30%;
    min-height: 100px;
  }
  .tabs .MuiTabs-flexContainer {
    width: 240px !important;
    font-size: 12px;
  }
  .tabs .MuiTabs-flexContainer button {
    font-size: 14px !important;
  }
  .createPolicy {
    font-size: 14px !important;
    margin-left: -10px !important;
  }
}
