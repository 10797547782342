.MuiDataGrid-columnHeaders {
  background: linear-gradient(
    90.16deg,
    rgba(0, 78, 146, 0.1) 1.48%,
    rgba(0, 4, 40, 0.1) 100%
  );
  border-radius: 5px;
  border-bottom: none !important;
}

.MuiDataGrid-iconSeparator {
  display: none !important;
}
.MuiDataGrid-cell {
  white-space: pre-wrap !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px dotted #004e92 !important;
}

.MuiDataGrid-root {
  border: none !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: black !important;
}
.MuiDataGrid-cell [title="active"] {
  background: #70c679;
  padding: 1px 8px 1px 8px;
  border-radius: 3px;
  color: #ffffff;
}
.MuiDataGrid-cell [title="published"] {
  background: #316496;
  padding: 1px 8px 1px 8px;
  border-radius: 3px;
  color: #ffffff;
}
.MuiDataGrid-cell [title="inactive"] {
  background: #ef7979;
  padding: 1px 8px 1px 8px;
  border-radius: 3px;
  color: #ffffff;
}
.css-19kzrtu {
  padding: 15px !important;
}
