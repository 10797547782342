body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Poppins"; */
}
body {
  margin: 0;
}

/* @media screen and (min-width: 1025px) and (max-width: 1440px) {
  .policy-wrapper {
    max-width: 1100px;
  }
} */
.policy-wrapper {
  padding: 25px 25px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.custom-drawer {
  background: linear-gradient(176.66deg, #004e92 1.26%, #000428 99.4%);
  height: 100vh;
  color: #ffff;
}
.drawer-devider {
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff33;
}
.list-btn:hover,
.list-btn.active {
  border-radius: 20px 0px 0px 20px;
  background-color: rgba(239, 234, 234, 0.2) !important;
  height: 40px;
}
.list-btn:hover,
.list-btn.active {
  border-radius: 20px 0px 0px 20px;
  background-color: rgba(239, 234, 234, 0.2) !important;
  height: 40px;
}
.list-btn:hover .list-text > span,
.list-btn.active .list-text > span {
  font-weight: 700 !important;
}
.policy-wrapper {
  max-width: 1600px;
}
.list-btn {
  padding: unset !important;
}
.list-text > span {
  margin: 20px;
}
@media screen and (min-width: 678px) and (max-width: 1024px) {
  .css-6ld0yo-MuiDrawer-docked .MuiDrawer-paper {
    width: 140px;
  }
}

.form-box {
  border: 1px solid #72767e;
  padding: 20px;
}
input,
.select-policy {
  display: block;
  box-sizing: border-box;
  border-radius: 10px !important;
  border: 1px solid #004e924d !important;
  padding: 10px 15px !important;
  margin-bottom: 10px;
  font-size: 14px;
  width: 100%;
  height: 53px;
  color: #000000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}
.addPolicy-container {
  padding-bottom: 15px;
}

.devider {
  border: 1px solid #72767e;
  margin-left: -20px !important;
  width: calc(100% + 40px) !important;
}
/* .policy-form {
  padding: 20px;
} */
label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 8px;
  /* margin-top: 20px; */
  font-size: 14px !important;
  color: #000000;
  font-weight: 500;
}
.select-policy .MuiSelect-select {
  padding: unset;
  color: #000000;
}
.select-policy fieldset {
  border: none;
}
.select-policy span {
  border: none;
  font-size: 14px;
  opacity: 0.6;
}
.select-policy-container {
  margin: unset !important;
  margin-top: unset;
  width: 100% !important;
}
textarea {
  width: 100% !important;
  height: 83px !important;
  resize: unset;
  background: #fcfcfc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border: 1px solid #004e924d;
  border-radius: 10px !important;
  font-size: 14px;
  color: #000000;
  font-family: unset !important;
  padding: 15px;
}
.date-start {
  min-width: 100% !important;
  height: 53px;
}
.box-btn {
  display: flex;
  align-items: center;
}
.box-btn {
  display: flex;
  align-items: center;
}
.addicon,
.clearIcon {
  background: #013f7c;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  margin-left: 15px;
  margin-bottom: 10px;
}
.addicon svg,
.clearIcon svg {
  fill: #fff;
}
.Geofence {
  border: 1px solid #72767e;
  padding: 20px;
}
.Geofence-inrr {
  display: flex;
  align-items: center;
  border: 1px dashed;
  width: 302px;
  padding: 15px;
  border-radius: 10px;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}
.Geofence-inrr a {
  color: #013067 !important;
}
.Geofence-view a {
  color: #013067 !important;
  font-size: 14px;
  font-weight: 500;
}
.Geofence-inrr svg {
  font-size: 20px;
}
.Rules {
  border: 1px solid #72767e;
  padding: 20px;
}
.Rules > textarea {
  height: 313px !important;
}
.footer-btn {
  display: flex;
}
.footer-btn .back {
  margin-right: 30px;
}
.footer-btn .back,
.footer-btn .save {
  border: 1px solid #004e92;
  text-align: center;
  height: 40px;
  width: 230px;
}
.applicable-tab .applicable-policy {
  min-width: 218px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border: 1px solid #004e924d;
  height: 53px;
}
.applicable-tab .applicable-policy fieldset {
  border: none;
}
.footer-btn .save {
  background: linear-gradient(92.12deg, #004e92 0.11%, #000428 99.81%);
  color: #fff;
}

.css-1xhypcz-MuiStack-root {
  padding-top: unset !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 31px !important;
  border-radius: 10px !important;
}
.MuiInputAdornment-root {
  position: absolute;
  right: 20px;
}
fieldset {
  border: unset !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: unset !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}
.width30 {
  width: 30%;
}
.width25 {
  width: 22.5%;
}
@media screen and (min-width: 678px) and (max-width: 1024px) {
  .row-form,
  .country-row,
  .policy-doc {
    display: block !important;
  }
  input,
  .select-policy {
    min-width: 100%;
  }
  .select-policy {
    max-width: 100% !important;
    min-width: 100%;
  }
  .select-policy-container {
    width: 100%;
  }
  .policy-doc input {
    min-width: 100%;
  }
  .policy-doc .applicable-policy {
    min-width: 100%;
  }
  .policy-doc {
    width: 100% !important;
  }
  .footer-btn .back {
    text-align: center;
  }
  .width30 {
    width: 100%;
  }
  .width25 {
    width: 100%;
  }
}

.modal-btn {
  padding: 15px 80px;
  background: linear-gradient(92.12deg, #004e92 0.11%, #000428 99.81%);
  color: #fff;
  text-align: center;
  display: grid;
  align-content: center;
  margin: 28px auto;
  cursor: pointer;
  width: 239px;
  height: 38px;
}
.policy-modal {
  width: 514px !important;
  height: 329px;
  align-items: center;
  padding: 30px 61px !important;
  border: unset !important;
  border-radius: 10px;
}
.policy-modal h2 {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
.policy-modal p {
  text-align: center;
  font-size: 14px !important;
  margin-top: 8px;
}
.modal-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 5px;
}

.appbar {
  box-shadow: 0px 0px 0px -3px rgb(0 0 0 / 20%),
    0px -2px 10px 0px rgb(0 0 0 / 14%), 0px -3px 0px 0px rgb(0 0 0 / 12%) !important;
}
.css-r6ewbb-MuiToolbar-root {
  justify-content: space-between;
}
.applicable-policy span {
  font-size: 14px;
  color: #00000066;
}

.geofencing-container {
  width: 100%;
}
.footer-geofencing-btn {
  display: flex;
  justify-content: center;
}
.footer-geofencing-btn .save {
  background: linear-gradient(92.12deg, #004e92 0.11%, #000428 99.81%);
  color: #fff;
}

.footer-geofencing-btn .back {
  margin-right: 30px;
}
.footer-geofencing-btn .back,
.footer-geofencing-btn .save {
  border: 1px solid #004e92;
  padding: 10px 100px;
  text-align: center;
}
.save {
  cursor: pointer;
}

.back {
  cursor: pointer;
}
.clear-geo {
  color: #013067;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.tabs .MuiTabs-flexContainer {
  width: 380px;
  display: flex;
  justify-content: space-between;
}
.tab {
  padding: unset !important;
  max-width: unset !important;
  min-width: unset !important;
  font-size: 16px !important;
  text-transform: none !important;
}
.tab.Mui-selected {
  color: #000 !important;
  font-weight: 700;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #000 !important;
}
.createPolicy {
  display: flex;
  color: #013b76 !important;
  font-weight: 400 !important;
  text-transform: unset !important;
  opacity: unset !important;
  padding: unset !important;
}
.tab-container p {
  display: grid;
}

.cards {
  display: grid;
  background: #fcfcfc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin: 15px;
  width: 30%;
  min-height: 137px;
  border: 1px solid #004e9280;
  border-radius: 10px !important;
  pointer-events: none;
}
.cards p {
  color: #3e4059;
}
.cards .MuiTypography-h5 {
  background: linear-gradient(180deg, #000428 0%, #004e92 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}
.cards-content {
  display: grid;
}
@media screen and (min-width: 678px) and (max-width: 1024px) {
  .cards {
    box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
    border-radius: 10px;
    margin: 10px;
    width: 26%;
    min-height: 100px;
  }
  .tabs .MuiTabs-flexContainer {
    width: 200px !important;
    font-size: 10px;
  }
  .tabs .MuiTabs-flexContainer button {
    font-size: 12px !important;
  }
  .createPolicy {
    font-size: 12px !important;
    margin-left: -10px !important;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1212px) {
  .cards {
    box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
    border-radius: 10px;
    margin: 10px;
    width: 30%;
    min-height: 100px;
  }
  .tabs .MuiTabs-flexContainer {
    width: 240px !important;
    font-size: 12px;
  }
  .tabs .MuiTabs-flexContainer button {
    font-size: 14px !important;
  }
  .createPolicy {
    font-size: 14px !important;
    margin-left: -10px !important;
  }
}

.MuiDataGrid-columnHeaders {
  background: linear-gradient(
    90.16deg,
    rgba(0, 78, 146, 0.1) 1.48%,
    rgba(0, 4, 40, 0.1) 100%
  );
  border-radius: 5px;
  border-bottom: none !important;
}

.MuiDataGrid-iconSeparator {
  display: none !important;
}
.MuiDataGrid-cell {
  white-space: pre-wrap !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px dotted #004e92 !important;
}

.MuiDataGrid-root {
  border: none !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: black !important;
}
.MuiDataGrid-cell [title="active"] {
  background: #70c679;
  padding: 1px 8px 1px 8px;
  border-radius: 3px;
  color: #ffffff;
}
.MuiDataGrid-cell [title="published"] {
  background: #316496;
  padding: 1px 8px 1px 8px;
  border-radius: 3px;
  color: #ffffff;
}
.MuiDataGrid-cell [title="inactive"] {
  background: #ef7979;
  padding: 1px 8px 1px 8px;
  border-radius: 3px;
  color: #ffffff;
}
.css-19kzrtu {
  padding: 15px !important;
}

.policy-document-link {
  max-width: 20px;
}
.policy-details-container {
  position: relative;
  width: 100%;
}
.form-data {
  border: 1px solid #72767e;
}
.policy-details-header {
  border-bottom: 1px solid #004e924d;
  width: 100%;
  padding: 20px;
}
.geofence-view {
  border: 1px solid #72767e;
}
.footer-btn {
  display: flex;
}
.footer-btn .back {
  margin-right: 30px;
}
.footer-btn .back,
.footer-btn .save {
  border: 1px solid #004e92;
  height: 40px;
  width: 230px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.footer-btn .save {
  background: linear-gradient(92.12deg, #004e92 0.11%, #000428 99.81%);
  color: #fff;
}
.css-1r215c6-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus {
  background-color: #ffff !important;
}
.status[data-value="Active"] {
  color: #32983c;
}
.status[data-value="Publish"] {
  color: #013873;
}
.status[data-value="Inactive"] {
  color: #ef7979;
}
.css-1r215c6-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1r215c6-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1r215c6-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: unset;
}
.select-policy-details .select-policy {
  min-width: 181px;
}
.MuiSelect-select[aria-label="Active"] {
  color: #32983c;
}
.MuiSelect-select[aria-label="Publish"] {
  color: #013873;
}
.MuiSelect-select[aria-label="Inactive"] {
  color: #ef7979;
}

.status[aria-selected="true"] {
  display: none;
}
.button {
  cursor: pointer;
}
@media screen and (min-width: 678px) and (max-width: 1024px) {
  .select-policy-details .select-policy {
    min-width: 100% !important;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1225px) {
  .select-policy {
    width: 100% !important;
  }
  .select-policy-details .select-policy {
    min-width: 100% !important;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1340px) {
  .select-policy-details .select-policy {
    min-width: 100% !important;
  }
}

